<template>

<div class="mobile-page-layout font-IRANSans">


    <div class="home-layout">

        <div class="tgcss-w-full tgcss-flex tgcss-items-center tgcss-mb-2 tgcss-px-1">
            <router-link :to="{ name: 'NewsIntro'}">
                <svg class="tgcss-w-8 tgcss-border tgcss-rounded-md tgcss-ml-2 !tgcss-text-gray-700" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M13.1717 12.0007L8.22192 7.05093L9.63614 5.63672L16.0001 12.0007L9.63614 18.3646L8.22192 16.9504L13.1717 12.0007Z"></path></svg>
            </router-link>
            <span v-if="list[categoryId] !== undefined" class="tgcss-text-base tgcss-font-bold grow">{{list[categoryId].name}}</span> 
        </div>

        <div class="home-row" v-if="list[categoryId] !== undefined">

            <slot v-for="item in list[categoryId].list">

                <div :key="item.id" v-if="item.type == 'brack'" class="tgcss-flex tgcss-font-semibold tgcss-text-sm tgcss-py-2 tgcss-pb-2 tgcss-mb-2 tgcss-mx-2 tgcss-border-b tgcss-border-dashed">
                    <svg class="tgcss-w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M7.78428 14L8.2047 10H4V8H8.41491L8.94043 3H10.9514L10.4259 8H14.4149L14.9404 3H16.9514L16.4259 8H20V10H16.2157L15.7953 14H20V16H15.5851L15.0596 21H13.0486L13.5741 16H9.58509L9.05957 21H7.04855L7.57407 16H4V14H7.78428ZM9.7953 14H13.7843L14.2047 10H10.2157L9.7953 14Z"></path></svg>
                    {{item.name}}
                </div>

                <router-link v-else :class="'home-col '+item.class" :to="{ name: 'ApplicationNews' , params: { category_id: item.id }, query: { subject: item.name }}">
                    <div class="home-widget">
                        <div class="home-widget-in">
                            <img v-if="item.image" class="home-widget-img" :src="'https://static.tgju.org/views/default/images/tgju/app/news/'+item.image">
                            <img v-if="!item.image" class="home-widget-img tgcss-opacity-40" src="https://static.tgju.org/views/default/images/tgju/app/news/tgju-app-gray-logo.svg">
                            <h2 class="home-widget-title">{{item.name}}</h2>
                        </div>

                    </div>  
                </router-link>  

            </slot>


        </div>
    </div>
</div>
</template>

<script>


export default {
    name: 'NewsIntro',
    components: {

    },
    data: function () {
        return {
            list: {
                "100003": {
                    id:83978,
                    name:'بخش‌های‌خبری',
                    list:[
                        {id:'93964',type:'id',name:'طلا و سکه',image:'طلا و سکه.svg'},
                        {id:'93965',type:'id',name:'ارزی',image:'ارزی.svg'},
                        {id:'93966',type:'id',name:'ارزهای دیجیتال',image:'ارزهای دیجیتال.svg'},
                        {id:'93968',type:'id',name:'بورس',image:'بورس.svg'},
                        {id:'93967',type:'id',name:'نفت و انرژی',image:'انرژی.svg'},
                        {id:'83980',type:'id',name:'خودرو',image:'خودرو.png'},
                        {id:'83981',type:'id',name:'قیمت‌ها',image:'قیمت ها.svg'},
                        {id:'83984',type:'id',name:'فناوری',image:'فناوری.svg'},
                        {id:'83979',type:'id',name:'مسکن',image:'مسکن.svg'},
                        {id:'83986',type:'id',name:'تولید و تجارت',image:'تولید و تجارت.svg'},
                        {id:'93969',type:'id',name:'بانکی',image:'بانک و بیمه.svg'},
                        {id:'83983',type:'id',name:'جهان',image:'جهان.svg'},
                    ]
                },
                "100005": {
                    id:'',
                    name:'تحلیل تکنیکال',
                    list:[
                        {id:'تحلیل-تکنیکال-طلا',type:'tag',name:'طلا',image:'تکنیکال/red/طلا.svg'},
                        {id:'تحلیل-تکنیکال-مثقال',type:'tag',name:'مثقال و آبشده',image:'تکنیکال/red/مثقال و آبشده.svg'},
                        {id:'تحلیل-تکنیکال-سکه',type:'tag',name:'سکه',image:'تکنیکال/red/سکه.svg'},
                        {id:'تحلیل-تکنیکال-دلار',type:'tag',name:'دلار',image:'تکنیکال/red/دلار.svg'},
                        {id:'تحلیل-تکنیکال-یورو',type:'tag',name:'یورو',image:'تکنیکال/red/یورو.svg'},
                        {id:'تحلیل-تکنیکال-نرخ-ارز-آزاد',type:'tag',name:'ارزهای آزاد',image:'تکنیکال/red/ارزهای آزاد.svg'},
                        {id:'تحلیل-تکنیکال-شاخص-کل-بورس',type:'tag',name:'شاخص‌کل‌بورس',image:'تکنیکال/red/شاخص کل بورس.svg'},
                        {id:'تحلیل-تکنیکال-سهام',type:'tag',name:'سهام',image:'تکنیکال/red/سهام.svg'},
                        {id:'تحلیل-تکنیکال-بیت-کوین',type:'tag',name:'بیت کوین',image:'تکنیکال/red/بیت‌کوین.svg'},
                        {id:'تحلیل-تکنیکال-اتریوم',type:'tag',name:'اتریوم',image:'تکنیکال/red/اتریوم.svg?001'},
                        {id:'تحلیل-تکنیکال-آلت-کوین-ها',type:'tag',name:'آلت کوین‌ها',image:'تکنیکال/red/آلت کوین‌ها.svg'},
                        {id:'تحلیل-تکنیکال-فلزات-گرانبها',type:'tag',name:'فلزات‌گران‌بها',image:'تکنیکال/red/فلزات گرانبها.svg'},
                        {id:'تحلیل-تکنیکال-فلزات-پایه',type:'tag',name:'فلزات پایه',image:'تکنیکال/red/فلزات پایه.svg'},
                        {id:'تحلیل-تکنیکال-کالاها',type:'tag',name:'کالاها',image:'تکنیکال/red/کالاها.svg'},
                        {id:'تحلیل-تکنیکال-فارکس',type:'tag',name:'فارکس',image:'تکنیکال/red/فارکس.svg'},
                        {id:'تحلیل-تکنیکال-نفت-و-انرژی',type:'tag',name:'نفت و انرژی',image:'تکنیکال/red/نفت و انرژی.svg'},
                        {id:'تحلیل-تکنیکال-شاخص-های-جهانی',type:'tag',name:'شاخص‌های‌جهانی',image:'تکنیکال/red/شاخص‌های جهانی.svg'},
                    ]
                },
                "100007": {
                    id:'',
                    name:'آکادمی',
                    list:[
                        {id:'97085',type:'brack',name:'مفاهیم پایه',image:''},
                        {id:'97081',type:'id',name:'اقتصاد',image:'آکادمی/اقتصادی.svg'},
                        {id:'97080',type:'id',name:'بازارهای مالی',image:'آکادمی/مالی.svg'},
                        {id:'97082',type:'id',name:'بانکی',image:'آکادمی/بانکی.svg'},
                        {id:'97083',type:'id',name:'بورس',image:'آکادمی/بورس.svg'},
                        {id:'97084',type:'id',name:'معاملات',image:'آکادمی/معاملات.svg'},
                        {id:'97085',type:'id',name:'تجارت',image:'آکادمی/تجارت.svg'},
                        {id:'97085',type:'brack',name:'آموزش‌ها',image:''},
                        {id:'97042',type:'id',name:'بازارهای معاملاتی',image:'آکادمی/بازارهای معاملاتی.svg'},
                        {id:'97041',type:'id',name:'تحلیل بازارها',image:'آکادمی/تحلیل بازارها.svg'},
                        {id:'97048',type:'id',name:'ترید در بازارها',image:'آکادمی/ترید در بازارها.svg'},
                    ]
                },
                "100008": {
                    id:'',
                    name:'دانشنامه‌‌اقتصادی',
                    list:[
                    {id:'97053',type:'id',name:'A',image:'دانشنامه/letter-a.svg'},
                    {id:'97055',type:'id',name:'B',image:'دانشنامه/letter-b.svg'},
                    {id:'97055',type:'id',name:'C',image:'دانشنامه/letter-c.svg'},
                    {id:'97056',type:'id',name:'D',image:'دانشنامه/letter-d.svg'},
                    {id:'97057',type:'id',name:'E',image:'دانشنامه/letter-e.svg'},
                    {id:'97058',type:'id',name:'F',image:'دانشنامه/letter-f.svg'},
                    {id:'97059',type:'id',name:'G',image:'دانشنامه/letter-g.svg'},
                    {id:'97060',type:'id',name:'H',image:'دانشنامه/letter-h.svg'},
                    {id:'97061',type:'id',name:'I',image:'دانشنامه/letter-i.svg'},
                    {id:'97062',type:'id',name:'J',image:'دانشنامه/letter-j.svg'},
                    {id:'97063',type:'id',name:'K',image:'دانشنامه/letter-k.svg'},
                    {id:'97064',type:'id',name:'L',image:'دانشنامه/letter-l.svg'},
                    {id:'97065',type:'id',name:'M',image:'دانشنامه/letter-m.svg'},
                    {id:'97066',type:'id',name:'N',image:'دانشنامه/letter-n.svg'},
                    {id:'97067',type:'id',name:'O',image:'دانشنامه/letter-o.svg'},
                    {id:'97068',type:'id',name:'P',image:'دانشنامه/letter-p.svg'},
                    {id:'97069',type:'id',name:'Q',image:'دانشنامه/letter-q.svg'},
                    {id:'97070',type:'id',name:'R',image:'دانشنامه/letter-r.svg'},
                    {id:'97071',type:'id',name:'S',image:'دانشنامه/letter-s.svg'},
                    {id:'97072',type:'id',name:'T',image:'دانشنامه/letter-t-.svg'},
                    {id:'97073',type:'id',name:'U',image:'دانشنامه/letter-u.svg'},
                    {id:'97074',type:'id',name:'V',image:'دانشنامه/letter-v.svg'},
                    {id:'97075',type:'id',name:'W',image:'دانشنامه/letter-w.svg'},
                    {id:'97076',type:'id',name:'X',image:'دانشنامه/letter-x.svg'},
                    {id:'97077',type:'id',name:'Y',image:'دانشنامه/letter-y.svg'},
                    {id:'97078',type:'id',name:'Z',image:'دانشنامه/letter-z.svg'},
                    ]
                },
                "100009": {
                    id:'',
                    name:'بروکرها بر اساس',
                    list:[
                        {id:'97828',type:'id',name:'بررسی بروکرها',image:'بروکرهای فارکس.svg'},
                        {id:'97824',type:'id',name:'بازارهای معاملاتی',image:'بازارهای معاملاتی.svg'},
                        {id:'97822',type:'id',name:'کارمزد',image:'کارمزد.svg'},
                        {id:'97821',type:'id',name:'مدل معاملات',image:'مدل معاملات.svg'},
                        {id:'97826',type:'id',name:'کشور',image:'کشور.svg'},
                        {id:'97825',type:'id',name:'واریز و برداشت',image:'واریز و برداشت.svg'},
                        {id:'97817',type:'id',name:'پلاتفرم',image:'پلاتفرم.svg'},
                        {id:'97819',type:'id',name:'ویژگی‌ها و ابزار',image:'ویژگی ها و ابزارها.svg'},
                        {id:'97818',type:'id',name:'اپلیکیشن',image:'اپلیکیشن.svg'},
                        {id:'97823',type:'id',name:'نوع حساب',image:'نوع حساب.svg'},
                        {id:'97820',type:'id',name:'مدل اجرایی',image:'مدل اجرایی.svg'},
                        {id:'97827',type:'id',name:'قوانین',image:'قوانین.svg'},
                    ]
                },
                "100010": {
                    id:'',
                    name:'ویکی کریپتو',
                    list:[
                        {id:'97830',type:'id',name:'ارزهای دیجیتال',image:'ارزهای دیجیتال-2.svg'},
                        {id:'97831',type:'id',name:'استیبل‌کوین‌ها',image:'استیبل‌کوین‌ها.svg'},
                        {id:'97837',type:'id',name:'چهره‌ها',image:'چهره‌ها.svg'},
                        {id:'97835',type:'id',name:'توکن های AI',image:'توکن های AI.svg'},
                        {id:'97832',type:'id',name:'NFTs',image:'NFTs.svg'},
                        {id:'97839',type:'id',name:'بازارهای معاملاتی',image:'-2بازارهای معاملاتی.svg'},
                        {id:'97836',type:'id',name:'بازارهای غیرمتمرکز',image:'بازارهای غیرمتمرکز.svg'},
                        {id:'97838',type:'id',name:'سازمان‌ها',image:'سازمان‌ها.svg'},
                        {id:'97833',type:'id',name:'DAOs',image:'Dao.svg'},
                        {id:'97834',type:'id',name:'Dapps',image:'Dapps.svg'},
                    ]
                },
                "100012": {
                    id:'',
                    name:'بلاگ',
                    list:[
                        {id:'83996',type:'id',name:'برترین برندها',image:'بلاگ/برترین برندها.svg'},
                        {id:'83997',type:'id',name:'برلیان',image:'بلاگ/برلیان.svg'},
                        {id:'84000',type:'id',name:'جواهر و ساعت',image:'بلاگ/جواهر و ساعت.svg'},
                        {id:'83996',type:'id',name:'ترین ها',image:'بلاگ/ترین ها.svg'},
                        {id:'84003',type:'id',name:'مشاور تولید',image:'بلاگ/مشاور تولید.svg'},
                        {id:'84001',type:'id',name:'سنگ‌ها و کانی‌ها',image:'بلاگ/سنگ ها و کانی ها.svg'},
                        {id:'83999',type:'id',name:'سکه و ارز',image:'بلاگ/سکه و ارز.svg'},
                        {id:'84007',type:'id',name:'شریعت و طلا',image:'بلاگ/شریعت و طلا.svg'},
                        {id:'84002',type:'id',name:'طراحی جواهر',image:'بلاگ/طراحی جواهر.svg'},
                        {id:'83998',type:'id',name:'طلا و نقره',image:'بلاگ/طلا و نقره.svg'},
                        {id:'84006',type:'id',name:'مشاور خرید',image:'بلاگ/مشاور خرید.svg'},
                        {id:'84004',type:'id',name:'معادن طلا',image:'بلاگ/معادن طلا.svg'},
                    ]
                }
            },
            Categories:[],
            categoryId: null,
        }
    },
    mounted() {
        this.getCategory();
        this.$store.commit('setAds', true);
        this.categoryId = this.$route.params.category_id;
    },
    computed: {},
    methods: {
        getCategory(){
            $.ajax({
                url: 'https://api.tgju.org/v1/news/list?limit=1&tag=%D8%AE%D8%A8%D8%B1-%DB%8C%DA%A9',
                method: 'get',
                complete: (res) => {
                    this.Categories = res.responseJSON.response;    
                }
            });
        },
    },
}
</script>